import PropTypes from 'prop-types'
import '../Button/Button.css'
import './Textfield.css'
import { crimson, borderColor } from '../../helpers/colors'

const Textfield = ({ value, type, placeholder, label, onChange, isTextarea, error, id, name}) => {

  const didChangeTextfieldText = (e) => {
    onChange(e.target.value)
  }

  const textfieldBorderColor = error.isError ? crimson : borderColor;

  return (
    <div className='textfield-container'>
      <p className='textfield-label'>{label}</p>
      <div className='textfield-area' style={{border: `${error.isError ? '2' : '1'}px solid ${textfieldBorderColor}`}} data-testid='textfield-area'>
        {isTextarea ? (
          <textarea 
            value={value}
            className='textfield textarea' 
            id={id} 
            name={name} 
            type={type} 
            placeholder={placeholder} 
            onChange={didChangeTextfieldText}
            style={{color: error.isError ? 'var(--red)' : 'var(--dark-gray)'}}
          />
        ) : (
          <input 
            value={value}
            className='textfield' 
            id={id} 
            name={name} 
            type={type} 
            placeholder={placeholder} 
            onChange={didChangeTextfieldText} 
            size='1' 
            data-testid='textfield-input'
            style={{color: error.isError ? 'var(--red)' : 'var(--dark-gray)'}}
          />
        )}
      </div>
      {error.isError && (
        <p className='error-message'>{error.message}</p>
      )}
    </div>
  )
}

Textfield.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.shape({
    message: PropTypes.string,
    isError: PropTypes.bool
  }),
  id: PropTypes.string,
  name: PropTypes.string
}

Textfield.defaultProps = {
  type: 'text',
  placeholder: '',
  isTextarea: false,
  error: {message: '', isError: false},
  id: '',
  name: ''
}

export default Textfield
