import { useEffect, useState } from 'react';
import { getPageContent } from '../../helpers/content';
import NavigationBar from '../../components/NavigationBar/NavigationBar'
import PageHeaderView from '../../components/PageHeaderView/PageHeaderView';
import FindUsSection from '../../components/FindUsSection/FindUsSection';

const VolunteeringPage = () => {

  const [content, setContent] = useState(null);

  useEffect(() => {
    document.title = 'Volunteering';
    getPageContent('VolunteeringPage').then(r => setContent(r))
  }, []);

  return (
    <>
      <NavigationBar currentPage={3}/>

      {content !== null &&
        <>
          <PageHeaderView image={content.image} title={content.title} col1Text={content.col1Text} col2Text={content.col2Text} />

          <FindUsSection />
        </>
      }
    </>
  )

}

export default VolunteeringPage
